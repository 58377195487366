<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-card header="DOWNLOAD TIMESHEETS (Excel)">
			<b-row>
				<b-col cols="12" xl="6">
					<b-card>
						<b-form-group v-if="this.initDates" label="From:" label-for="from" :label-cols="6">
							<b-form-input v-model="from" type="date" id="from"></b-form-input>
						</b-form-group>
						<b-form-group label="To:" label-for="to" :label-cols="6">
							<b-form-input v-model="to" type="date" id="to"></b-form-input>
						</b-form-group>
						<b-row>
							<b-col sm="5">
								<b-button variant="primary" v-on:click="downloadTS">Download </b-button>
							</b-col>
							<b-col sm="5">
								<b-button variant="primary" v-on:click="loadTS">Send by Email</b-button>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
			</b-row>
			<loading :active.sync="isLoading" :is-full-page="true"></loading>
		</b-card>
	</errorContainer>
</template>

<script>
import { BASE_URL } from '../vue-apollo';
import { store } from '@/store';
import axios from 'axios';

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'Timesheets',
	components: {
		Loading
	},
	data: () => {
		return {
			from: '',
			to: '',
			warning: '',
			erreur: {},
			isLoading: false
		};
	},
	computed: {
		initDates() {
			if (this.from.length == 0) {
				var currentDateWithFormat = new Date().toJSON().slice(0, 10);
				this.from = currentDateWithFormat.substring(0, 8) + '01';
				this.to = currentDateWithFormat;
			}
			return true;
		}
	},

	methods: {
		loadTS() {
			this.isLoading = true;
			this.warning = '';

			var fromDate = this.from + 'T00:00:00Z';
			var toDate = this.to + 'T00:00:00Z';

			var config = {
				headers: {
					Authorization: 'Bearer ' + store.state.token
				}
			};

			axios
				.get(process.env.VUE_APP_GQL + '/timesheetGridFromTo/?From=' + fromDate + '&To=' + toDate + '&Email=1', config)
				.then((response) => {
					console.log({
						response
					});
					if (response.status == 200) {
						alert('The Timesheets have been sent to your email address.');
					}
				})
				.catch((error) => {
					console.log({
						error
					});
					if (error.message.includes('Network')) {
						this.warning = 'Network error.';
					} else if (error.response) {
						if (error.response.status == 401) {
							this.warning = 'Not connected.';
						} else if (error.response.status == 400) {
							this.warning = 'Invalid format.';
						} else if (error.response.status == 404) {
							this.warning = 'No Data in the selected period.';
						}
					}
				})
				.finally(() => (this.isLoading = false));
		},

		downloadTS() {
			console.log('indownload');
			this.isLoading = true;
			this.warning = '';

			var fromDate = this.from + 'T00:00:00Z';
			var toDate = this.to + 'T00:00:00Z';

			var config = {
				headers: {
					Authorization: 'Bearer ' + store.state.token
				}
			};

			axios
				.get(process.env.VUE_APP_GQL + '/timesheetGridFromTo/?From=' + fromDate + '&To=' + toDate + '&Email=0', config)
				.then((response) => {
					if (response.status == 200) {
						console.log('response:', response);
						var urlStr = process.env.VUE_APP_GQL + '/images/' + response.data;
						console.log('urlStr:', urlStr);
						this.$nextTick(function () {
							console.log('in nexttick url:', urlStr);
							window.location.href = urlStr;
							// window.open(urlStr); // in a new window
						});

						/*
                                                        var a = document.createElement("a");
                                                        a.href = urlStr;
                                                        var fileName = url.split("/").pop();
                                                        a.download = fileName;
                                                        document.body.appendChild(a);
                                                        a.click();
                                                        window.URL.revokeObjectURL(url);
                                                        a.remove();
                            */
					}
				})
				.catch((error) => {
					console.log({
						error
					});
					if (error.message.includes('Network')) {
						this.warning = 'Network error.';
					} else if (error.response) {
						if (error.response.status == 401) {
							this.warning = 'Not connected.';
						} else if (error.response.status == 400) {
							this.warning = 'Invalid format.';
						} else if (error.response.status == 404) {
							this.warning = 'No Data in the selected period.';
						}
					}
				})
				.finally(() => (this.isLoading = false));
		}
	}
};
</script>
